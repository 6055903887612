<template>
    <div class="dealer">
        <a href="javascript:void(0)" class="scrollT" ref="dealer"></a>
        <div class="container">
            <div class="main">
                <div class="title wow fadeInUp">Authorized Dealer <br>Query</div>
                <div class="box wow fadeInUp" v-if="resultFlag == 0">
                    <div class="search">
                        <input type="text" class="text" v-model="jxsmc" placeholder="Please input the full name of the dealer you are inquiring">
                        <button class="btn" @click="searchDealer"></button>
                    </div>
                    <div class="info">
                        <p>We are only responsible for the PV products sold by DMEGC Solar’s authorized dealers.</p>
                        <p>Before purchasing any DMEGC PV products from a dealer, we recommend you verfiy if it is authorized through this page.</p>
                    </div>
                </div>
                <div class="result wow fadeInUp" v-else-if="resultFlag === 1">
                    <h4>The dealer you are inquiring</h4>
                    <div class="name">{{dealerName}}</div>
                    <div class="txt">
                        is DMEGC Solar’s authorized dealers, please rest assured to purchase <br>products and services.
                    </div>
                </div>
                <div class="no-result wow fadeInUp" v-else>
                    <h4>Sorry, we do not find the dealer <br>you are inquiring.</h4>
                    <div class="txt">
                        Please purchase products and services from DMEGC Solar’s <br>authorized dealers.
                    </div>
                    <a href="javascript:void" class="back" @click="resetSearch">Back <i></i></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { WOW } from 'wowjs'
    import { queryDealer } from '../../request/api'
    export default {
        name:'Dealer',
        metaInfo:{
            title:"DMEGC Solar-product",
            meta: [{                 // set meta
                name: 'keyWords',
                content: 'DMEGC Solar'
            },{
                name:'description',
                content:'DMEGC Solar'
            }]
        },
        data(){
            return {
                jxsmc: '',
                resultFlag: 0,
                dealerName: ''
            }
        },
        mounted(){
            this.$nextTick(() => {

                const wow = new WOW({
                    animateClass: 'animated',
                    offset: 100
                })
                wow.init();
                this.$refs.dealer.scrollIntoView();
            })
        },
        methods: {
            searchDealer(){
                if(this.jxsmc === '') {

                    alert('Please input the full name of the dealer you are inquiring')
                }else {
                    queryDealer({jxsmc: this.jxsmc}).then(res => {

                        if(res.code == '00000000') {

                            this.resultFlag = 1
                            this.dealerName = res.data.jxsmcen
                            this.jxsmc = ''
                        } else {
                            this.resultFlag = 2
                            this.jxsmc = ''
                        }
                    })
                }
            },
            resetSearch(){

                this.jxsmc = ''
                this.dealerName = ''
                this.resultFlag = 0
                this.$refs.dealer.scrollIntoView({behavior:'smooth'})
            }
        }
    }
</script>

<style lang="scss" scoped>
.dealer{
    background: url(../../assets/img/dealer_banner.jpg) no-repeat center center;
    background-size: cover;
    padding-top: 9.5833vw;
    height: 45.8333vw;
    box-sizing: border-box;
    position: relative;
    .main{
        width: 1124px;
        margin: 0 auto;
    }
    .title{
        font-size: 2.7083vw;
        line-height: 1.1154;
        color: #fff;
        text-align: center;
        font-weight: 300;
    }
    .box{
        margin-top: 5.3646vw;
    }
    .search{
        display: flex;
        justify-content: space-between;
        .text{
            width: calc(100% - 82px);
            height: 72px;
            background: #F2F4F8;
            box-sizing: border-box;
            padding: 0 40px;
            font-size: 20px;
            color: #A4AAB6;
        }
        .btn{
            border: none;
            width: 82px;
            height: 72px;
            background: url(../../assets/images/search_w.png) no-repeat center center #E60011;
            background-size: 26px 26px;
        }
    }
    .info{
        font-size: 20px;
        line-height: 1.5;
        color: #fff;
        text-align: center;
        width: 804px;
        margin: 1.5625vw auto 0;
        p{
            margin-bottom: 10px;
        }
    }
    .result{
        margin-top: 3.3854vw;
        h4{
            font-size: 1.3542vw;
            font-weight: bold;
            line-height: 1.154;
            color: #fff;
            text-align: center;
        }
    }
    .name{
        font-size: 3.0729vw;
        text-align: center;
        line-height: 1;
        margin-top: 1.5625vw;
        font-weight: bold;
        color: #fff;
    }
    .txt{
        font-size: 1.0417vw;
        left: 1.5;
        text-align: center;
        font-weight: bold;
        color: #fff;
        margin-top: 2.6042vw;
    }
    .no-result{
        margin-top: 2.6042vw;
        h4{
            text-align: center;
            font-size: 3.0729vw;
            line-height: 1.1;
            color: #fff;
            font-weight: bold;
        }
        .txt{
            margin-top: 3.5417vw;
        }
    }
    .back{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 260px;
        height: 50px;
        box-sizing: border-box;
        padding: 0 30px;
        font-size: 16px;
        color: #fff;
        background: #E60011;
        margin: 3.6458vw auto 0;
    }
    i{
        display: block;
        width: 24px;
        height: 24px;
        background: url(../../assets/images/arrowR.png) no-repeat;
        background-size: cover;
    }
}
@media screen and (max-width: 750px) {
    .dealer{
        height: auto;
        padding: 1rem 0 1.6rem;
        .main{
            width: 100%;
        }
        .title{
            font-size: .4rem;
        }
        .box{
            margin-top: .6rem;
        }
        .search{
            .text{
                width: calc(100% - 1.2rem);
                height: 1rem;
                padding: 0 .3rem;
                font-size: .28rem;
            }
            .btn{
                width: 1.2rem;
                height: 1rem;
                background-size: .52rem .52rem;
            }
        }
        .info{
            margin-top: .3rem;
            font-size: .28rem;
            width: 100%;
            p{
                margin-bottom: .2rem;
            }
        }
        .result{
            margin-top: .6rem;
            h4{
                font-size: .36rem;
            }
        }
        .name{
            font-size: .5rem;
            margin-top: .6rem;
        }
        .no-result{
            margin-top: .6rem;
            h4{
                font-size: .36rem;
            }
            .txt{
                margin-top: .5rem;
            }
        }
        .txt{
            font-size: .28rem;
        }
        a{
            width: 4.2rem;
            height: .8rem;
            font-size: .3rem;
            padding: 0 .6rem;
            margin-top: .6rem;
        }
        i{
            width: .48rem;
            height: .48rem;
        }
    }
}
</style>